import React from 'react'
import styled from 'styled-components';

const SubscribSectionStyle = styled.div` 
@media (max-width: 991px)
{
    .form-subscribe .btn{font-size: 17px;}
    .form-subscribe .form-inline .form-group label.col-form-label {
        max-width: 180px;
        flex: 180px;
    }
    .form-subscribe .form-inline .form-group input#staticEmail {
        max-width: calc(100% - 180px);
        flex: calc(100% - 180px);
        margin-left: 0;
        margin-right: 0;
    }
    .form-subscribe .form-inline .form-group { 
        max-width: calc(100% - 200px);
        flex: calc(100% - 200px);
    }
}

@media (max-width: 767px)
{
    .form-subscribe form.form-inline,
.form-subscribe form.form-inline .form-group {
    flex-wrap: wrap;
}
input#staticEmail{margin-left: 0;}
 
.form-subscribe .form-inline .form-group label.col-form-label {
    max-width: 100%;
    -webkit-flex: 100%;
    -ms-flex: 100%;
    flex: 100%;
}
.form-subscribe .form-inline .form-group {
    max-width: 100%;
    flex: 100%;
}
.form-subscribe .form-inline .form-group input#staticEmail {
    width: 100%;
    max-width: 100%;
    flex: 100%;
}
.welcome-section.gauratee-section .form-subscribe .btn.btn-primary {
    margin: 20px auto;
}
.question-text .stay-touch-part {
    margin-left: 0;
}
.form-subscribe .form-inline .form-group label.col-form-label {
    padding-bottom: 15px;
    padding-top: 5px;
}
}
`;

function SubscribSection({ innerText }) {
    return (
        <SubscribSectionStyle>
            <div className="welcome-section gauratee-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            {/*<div className="question-text">
                                <h1 style={{ 'color': '#16356a' }}> {innerText}</h1>
                                <div className="stay-touch-part">
                                    <h5>Stay in Touch</h5>
                                </div>
                                <div className="form-subscribe">
                                    <form className="form-inline">
                                        <div className="form-group">
                                            <label className="col-form-label">Subscribe</label>
                                            <input type="text" readOnly className="form-control-plaintext" id="staticEmail" value="*Your email address " />
                                        </div>
                                        <button type="submit" className="btn btn-primary">Subscribe</button>
                                    </form>
                                </div>
    </div>*/}
                        </div>
                    </div>
                </div>
            </div>
        </SubscribSectionStyle>
    )
}

export default SubscribSection;
